@import "theme";#texture-picker {
    position: fixed;top: 20px;
        right: 20px;background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px 12px;
    p {
        margin: 0;
        @include fluidType(14px, 15px);
    }
    .info {
        background-color: rgba(#ffd68d, 0.7);
        border-radius: 4px;
        font-size: 13px;
        // color: #999;
        padding: 10px;
        margin-top: 10px;
        max-width: 200px;
        line-height: 1.3em;
    }
    .texture-colors {
        display: flex;
        > div {
            width: 30px;
            height: 30px;
            margin: 4px;
            border: 1px solid #eee;
            border-radius: 4px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    #red {background-color: orangered;}
    #blue { background-color: lightblue; }
    #black { background-color: #111; }
    #purple { background-color: rebeccapurple; }
    #green { background-color: mediumseagreen; }
}// Textures
.texture-black {
    background-image: url('/assets/textures/black.jpg');

    h2 {
        color: #999;
    }
}

.texture-blue {
    background-image: url('/assets/textures/blue.jpg');

    h2 {
        color: #ddd;
    }

    .social i:hover {
        color: #eee;
    }

    .post-date {
        color: #ccc;
    }
}

.texture-red {
    background-image: url('/assets/textures/red.jpg');
}

.texture-purple {
    background-image: url('/assets/textures/purple.jpg');
}

.texture-green {
    background-image: url('/assets/textures/green.jpg');

    .post-date {
        color: #ccc;
    }
}

.texture-red,
.texture-purple {
    h2 {
        color: #ccc;
    }

    .social i {
        color: #ddd;

        &:hover {
            color: lighten(#ddd, 7%);
        }
    }

    .post-date {
        color: #ddd;
    }
}
